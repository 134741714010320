import React from "react"
import PropTypes from "prop-types"

let required = null

const Input = props => {
  if (props.required) {
    required = "*"
  } else {
    required = null
  }

  return (
    <React.Fragment>
      <label
        className="block text-gray-900 text-sm font-medium mb-2"
        htmlFor={props.id}
      >
        {props.label}
        {required}
      </label>
      <input
        className="shadow appearance-none border rounded w-full p-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        required={props.required}
        min={props.min}
        max={props.max}
        pattern={props.pattern}
        autoComplete={props.autoComplete}
        inputMode={props.inputMode}
      />
    </React.Fragment>
  )
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  inputMode: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  pattern: PropTypes.string,
}

export default Input
