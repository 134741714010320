import React from "react"
import Style from "./CheckboxCard.module.scss"

class CheckboxCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: false,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    })
  }
  render() {
    return (
      <div
        className="flex self-stretch w-full"
        onClick={this.props.toggleMenu}
        onKeyDown={this.props.toggleMenu}
        role="button"
        tabIndex={0}
      >
        <input
          className="hidden"
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          id={this.props.id}
          onChange={this.handleChange}
        />
        <label
          className={`w-full shadow rounded text-center p-5 transition-colors duration-300 self-stretch cursor-pointer border border-transparent relative ${
            Style.checkboxCard
          } ${this.state.isChecked ? Style.active : ""}`}
          htmlFor={this.props.id}
        >
          <div className="svg-checkbox-container">
            <div className="text-center">{this.props.svg}</div>
            <div>{this.props.label}</div>
          </div>
        </label>
      </div>
    )
  }
}

export default CheckboxCard
