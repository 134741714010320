import React, { Component } from "react"

class Wizard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      maxSteps: 0,
    }

    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
  }

  componentDidMount() {
    this.setState({
      maxSteps: this.getSteps().length,
    })

    this.getSteps().forEach(step => {
      step.classList.add("hidden")
    })
    this.getSteps()[this.state.currentStep].classList.remove("hidden")
  }

  validate() {
    let steps = this.getSteps()
    let currentInputs = [
      ...steps[this.state.currentStep].getElementsByTagName("input"),
    ]
    for (let i = 0; i < currentInputs.length; i++) {
      if (currentInputs[i].checkValidity() === false) {
        currentInputs[i].reportValidity()
        return false
      }
    }
  }

  nextStep() {
    if (this.validate() !== false) {
      let step = this.state.currentStep
      step++
      this.setState({
        currentStep: step,
      })
      this.updateStep(step)
    }
  }

  prevStep() {
    let step = this.state.currentStep
    step--
    this.setState({
      currentStep: step,
    })
    this.updateStep(step)
  }

  updateStep(currentStep) {
    this.getSteps().forEach(step => {
      step.classList.add("hidden")
    })
    this.getSteps()[currentStep].classList.remove("hidden")
  }

  getSteps() {
    let steps = [...document.getElementById("wizard").children]
    return steps
  }

  render() {
    const nextButton = () => {
      if (this.state.currentStep < this.state.maxSteps - 1) {
        return (
          <button
            type="button"
            onClick={this.nextStep}
            className="btn  border border-transparent bg-indigo-500 inline-block w-full text-lg transition duration-300 ease-in-out text-white text-center py-3 px-3 rounded shadow"
          >
            Weiter
          </button>
        )
      }
    }
    const prevButton = () => {
      if (this.state.currentStep > 0) {
        return (
          <button
            type="button"
            onClick={this.prevStep}
            className="btn border border-gray-300 text-gray-700 bg-white inline-block w-full text-lg transition duration-300 ease-in-out  text-center py-3 px-3 rounded shadow"
          >
            Zurück
          </button>
        )
      }
    }

    const submitButton = () => {
      if (this.state.currentStep === this.state.maxSteps - 1) {
        return (
          <button
            className="inline-flex border border-transparent items-center justify-center btn bgh-gradient inline-block w-full text-lg transition duration-300 ease-in-out text-white text-center py-3 px-3 rounded shadow"
            type="submit"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            Jetzt anfragen
          </button>
        )
      }
    }

    return (
      <React.Fragment>
        <div id="wizard">{this.props.children}</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex justify-end sm:order-last">
            {nextButton()}
            {submitButton()}
          </div>
          <div>{prevButton()}</div>
        </div>
      </React.Fragment>
    )
  }
}

export default Wizard
