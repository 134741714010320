import React from "react"

const RequestLeftCol = props => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full mb-5">{props.children}</div>
    </div>
  )
}

export default RequestLeftCol
