import React from "react"
import FooterMin from "./partials/navigation/FooterMin"
import FloatingActionButton from "./partials/FloatingActionButton"
import externalScripts from "./../modules/ExternalScripts"
import klaro from "./../modules/Klaro"

const Layout = props => {
  externalScripts()
  klaro()
  return (
    <React.Fragment>
      <div>{props.children}</div>
      <FooterMin />
      <FloatingActionButton />
    </React.Fragment>
  )
}

export default Layout
