import React from "react"
import { Link } from "gatsby"

const FooterMin = () => {
  const year = new Date().getFullYear()

  const openKlaro = () => {
    if (typeof window !== `undefined`) {
      window.klaro.show()
    }
  }

  return (
    <div className=" bg-gray-100">
      <footer>
        <div>
          <div className="flex pb-5 m-auto pt-5 text-gray-700 text-sm flex-col">
            <div className="w-full mt-2 px-5">
              <p className="mb-2">
                &copy; {year} Florian Dierda. Alle Rechte vorbehalten.
              </p>
              <nav className="inline flex flex-wrap">
                <Link to="/impressum/" className="inline hover:underline mr-5">
                  Impressum
                </Link>
                <Link
                  to="/datenschutz/"
                  className="inline hover:underline mr-5"
                >
                  Datenschutz
                </Link>
                <button
                  className="inline hover:underline mr-5"
                  onClick={openKlaro}
                >
                  Cookie Einstellungen
                </button>
              </nav>
            </div>
            <div className="mt-8 px-5 text-small text-gray-500">
              <p>
                Erstellt mit{" "}
                <span role="img" aria-label="Herz Emoticon">
                  ❤️
                </span>{" "}
                zum Detail in Kassel.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterMin
